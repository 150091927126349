<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
      v-b-tooltip.hover.v-warning
      title="Descargar Reporte"
      variant="warning"
      class="btn-icon"
      @click="generarExcel(ticketsData)"
    >
      <feather-icon
        icon="DownloadIcon"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getlistado } from '@/utils/catalogos'
import { getHoras } from '@/utils/tickets'
import { calcularFecha } from '@/utils/fechas'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    ticketsData: {
      type: Array,
      default: () => [],
    },
    origen: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    recalcularTotal(item) {
      let total = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const row of item.items) {
        total += row.precio ? row.precio : 0
      }
      return total
    },
    async generarExcel(ticketsData) {
      // eslint-disable-next-line global-require,no-shadow
      const xl = require('excel4node')
      const wb = new xl.Workbook()
      const ws = wb.addWorksheet('Hoja 1')

      const trabajosCorrectivos = await getlistado(33, 'Trabajos Correctivos', false, this.usuario)
      const styleTitulo = wb.createStyle({
        font: {
          bold: true,
          size: 20,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleTituloSecundario = wb.createStyle({
        font: {
          bold: true,
          size: 13,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleSubtitulo = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleValores = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldas = wb.createStyle({
        font: {
          size: 10,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })

      // <!---------Titulos de tabla ---------------->
      ws.cell(6, 1).string('No. Ticket').style(styleValores)
      ws.cell(6, 2).string('Persona Reporta').style(styleValores)
      ws.cell(6, 3).string('Canal').style(styleValores)
      ws.cell(6, 4).string('Fuente').style(styleValores)
      ws.cell(6, 5).string('Técnico Encargado').style(styleValores)
      ws.cell(6, 6).string('No. Poste').style(styleValores)
      ws.cell(6, 7).string('Serie Lámpara').style(styleValores)
      ws.cell(6, 8).string('Falla').style(styleValores)
      ws.cell(6, 9).string('Falla Inicial del Ticket').style(styleValores)
      ws.cell(6, 10).string('Grupo').style(styleValores)
      ws.cell(6, 11).string('Etapa').style(styleValores)
      ws.cell(6, 12).string('Fecha de Creación').style(styleValores)
      ws.cell(6, 13).string('Fecha de Atención').style(styleValores)
      ws.cell(6, 14).string('Fecha de Verificación').style(styleValores)
      ws.cell(6, 15).string('Fecha de Finalización').style(styleValores)
      ws.cell(6, 16).string('Horas Atención').style(styleValores)
      ws.cell(6, 17).string('Horas Verificación').style(styleValores)
      ws.cell(6, 18).string('Comentarios').style(styleValores)

      let countTitulos = 0
      const titulosCodigos = []
      // <!---------Total ---------------->
      trabajosCorrectivos.forEach(trabajo => {
        if (trabajo.codigo && trabajo.codigo !== '') {
          ws.cell(6, 19 + countTitulos).string(trabajo.codigo).style(styleValores)
          titulosCodigos.push({ id: trabajo.id, type: 'DIURNO', posicion: countTitulos })
          countTitulos += 1
          if (trabajo.codigoNocturno && trabajo.codigoNocturno !== '') {
            ws.cell(6, 19 + countTitulos).string(trabajo.codigoNocturno).style(styleValores)
            titulosCodigos.push({ id: trabajo.id, type: 'NOCTURNO', posicion: countTitulos })
            countTitulos += 1
          }
        } else {
          ws.cell(6, 19 + countTitulos).string(trabajo.nombre).style(styleValores)
          titulosCodigos.push({ id: trabajo.id, type: 'ALL', posicion: countTitulos })
          countTitulos += 1
        }
      })
      const columnaTotal = 19 + countTitulos
      ws.cell(6, columnaTotal).string('Total').style(styleValores)

      if (this.usuario.company.name === 'APMIX') {
        ws.cell(6, columnaTotal + 1).string('ID Tickets').style(styleValores)
      }

      let numeroFila = 7
      // eslint-disable-next-line no-restricted-syntax
      for await (const ticket of ticketsData) {
        let sumaTotalPreciosVenta = 0
        ws.cell(numeroFila, 1).number(ticket.ticket ? ticket.ticket : '').style(styleCeldas)
        ws.cell(numeroFila, 2).string(ticket.usuarioReporta ? ticket.usuarioReporta.nombre : '').style(styleCeldas)
        ws.cell(numeroFila, 3).string(ticket.canal).style(styleCeldas)
        ws.cell(numeroFila, 4).string(ticket.fuente && ticket.fuente.nombre).style(styleCeldas)
        ws.cell(numeroFila, 5).string(ticket.tecnicoEncargado && ticket.tecnicoEncargado.nombre).style(styleCeldas)
        ws.cell(numeroFila, 6).string(ticket.punto && ticket.punto.numeroPoste).style(styleCeldas)
        ws.cell(numeroFila, 7).string(ticket.punto ? ticket.punto.serieLampara : '').style(styleCeldas)
        ws.cell(numeroFila, 8).string(ticket.falla.nombre).style(styleCeldas)

        // eslint-disable-next-line no-nested-ternary
        const fallaInicial = ticket.seguimiento && ticket.seguimiento.length > 1
        // eslint-disable-next-line no-nested-ternary
          ? (ticket.seguimiento[1].fallaInicioTicket && ticket.seguimiento[1].fallaInicioTicket.nombre
            ? ticket.seguimiento[1].fallaInicioTicket.nombre
            : (ticket.seguimiento[2] && ticket.seguimiento[2].fallaInicioTicket && ticket.seguimiento[2].fallaInicioTicket.nombre
              ? ticket.seguimiento[2].fallaInicioTicket.nombre
              : ''))
          : ''
        ws.cell(numeroFila, 9).string(fallaInicial).style(styleCeldas)
        ws.cell(numeroFila, 10).string(`G${ticket.punto ? ticket.punto.grupo : ''}`).style(styleCeldas)
        ws.cell(numeroFila, 11).string(ticket.estado).style(styleCeldas)
        ws.cell(numeroFila, 12).string(ticket.fechaCreacion ? ticket.fechaCreacion : '').style(styleCeldas)
        ws.cell(numeroFila, 13).string(ticket.fechaAtencion ? calcularFecha(ticket.fechaAtencion) : '').style(styleCeldas)
        ws.cell(numeroFila, 14).string(ticket.fechaVerificacion ? calcularFecha(ticket.fechaVerificacion) : '').style(styleCeldas)
        ws.cell(numeroFila, 15).string(ticket.fechaFinalizacion ? calcularFecha(ticket.fechaFinalizacion) : '').style(styleCeldas)
        ws.cell(numeroFila, 16).string(ticket.estado !== 'ANULADO' ? getHoras(ticket, 'A') : '').style(styleCeldas)
        ws.cell(numeroFila, 17).string(ticket.estado !== 'ANULADO' ? getHoras(ticket, 'V') : '').style(styleCeldas)
        ws.cell(numeroFila, 18).string(ticket.comentarios).style(styleCeldas)

        const trabajosTicket = Array.isArray(ticket.trabajosCorrectivos) ? ticket.trabajosCorrectivos : []

        countTitulos = 0
        const fechaFinalizacion = new Date(ticket.fechaFinalizacion)
        const hora = fechaFinalizacion.getHours()
        const esNocturno = hora >= 19 || hora < 6 // Después de las 7 PM o antes de las 6 AM

        // eslint-disable-next-line no-loop-func
        trabajosCorrectivos.forEach(trabajoCatalogo => {
          const ticketTrabajo = trabajosTicket.find(trabajoTicket => trabajoTicket.id === trabajoCatalogo.id)
          const titulos = titulosCodigos.filter(titulo => titulo.id === trabajoCatalogo.id)
          let precio = 0

          if (ticketTrabajo) {
            const objPrecio = ticketTrabajo.precio || trabajoCatalogo.precio

            if (objPrecio) {
              precio = esNocturno && objPrecio.precioVentaNocturno
                ? objPrecio.precioVentaNocturno
                : objPrecio.precioVenta || 0
            }

            if (titulos.length > 1) {
              ws.cell(numeroFila, 19 + countTitulos).number(!esNocturno ? precio : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
              // eslint-disable-next-line no-plusplus
              countTitulos++
              ws.cell(numeroFila, 19 + countTitulos).number(esNocturno ? precio : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
              // eslint-disable-next-line no-plusplus
              countTitulos++
            } else {
              ws.cell(numeroFila, 19 + countTitulos).number(precio).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
              // eslint-disable-next-line no-plusplus
              countTitulos++
            }

            sumaTotalPreciosVenta += precio
          } else {
            ws.cell(numeroFila, 19 + countTitulos).number(!esNocturno ? precio : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
            // eslint-disable-next-line no-plusplus
            countTitulos++
            if (titulos.length > 1) {
              ws.cell(numeroFila, 19 + countTitulos).number(!esNocturno ? precio : 0).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
              // eslint-disable-next-line no-plusplus
              countTitulos++
            }
          }
        })

        ws.cell(numeroFila, columnaTotal).number(sumaTotalPreciosVenta).style({ ...styleCeldas, numberFormat: 'Q#,##0.00' })
        if (this.usuario.company.name === 'APMIX') {
          ws.cell(numeroFila, columnaTotal + 1).string(ticket.id).style(styleCeldas)
        }
        // eslint-disable-next-line no-plusplus
        numeroFila++
      }

      ws.cell(1, 1, 2, columnaTotal, true).string(this.origen.company.companyStyle.headerReportes).style(styleTitulo)
      ws.cell(3, 1, 3, columnaTotal, true).string('TICKETS').style(styleTituloSecundario)
      ws.cell(4, 1, 4, columnaTotal, true).string('REPORTE GENERAL DE TICKETS').style(styleSubtitulo)
      // <!---------Fin Tabla ---------------->

      // <!---------Width de las celdas de la 1 a la 13 ---------------->
      ws.column(1).setWidth(11)
      ws.column(2).setWidth(18)
      ws.column(3).setWidth(12)
      ws.column(4).setWidth(15)
      ws.column(5).setWidth(20)
      ws.column(6).setWidth(11)
      ws.column(7).setWidth(20)
      ws.column(8).setWidth(30)
      ws.column(9).setWidth(40)
      ws.column(10).setWidth(8)
      ws.column(11).setWidth(13)
      ws.column(12).setWidth(20)
      ws.column(13).setWidth(20)
      ws.column(14).setWidth(20)
      ws.column(15).setWidth(20)
      ws.column(16).setWidth(20)
      ws.column(17).setWidth(15)
      ws.column(18).setWidth(18)
      if (this.usuario.company.name === 'APMIX') {
        ws.column(columnaTotal + 1).setWidth(30)
      }
      ws.column(columnaTotal).setWidth(15)

      // Se puede agregar un Height a las celdas
      // for (let i = 3; i <= 7; i++) {
      //   ws.row(i).setHeight(25)
      // }

      const buffer = await wb.writeToBuffer()
      const string = buffer.toString('base64')
      const linkSource = `data:application/xlsx;base64,${string}`
      const downloadLink = document.createElement('a')
      const fileName = 'REPORTE GENERAL DE TICKETS.xlsx'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },
}
</script>
